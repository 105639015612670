import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import React from "react"

import BlogRoll from "../../components/blogRoll"
import ComboCourseList from "../../components/comboCourseList"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default class BlogIndexPage extends React.Component {
  public render() {
    return (
      <Layout>
        <SEO
          title="Courses"
          description="View the wide range of ITEC and CIBTAC professional qualifications courses that we offer at The Institute of Beauty such as Beauty Therapy, Make-Up and Eyebrow Treatments"
        />
        <div
          style={{
            backgroundColor: "#FAF9FD",
            paddingTop: "40px",
            paddingBottom: "20px",
            marginBottom: "40px",
          }}
        >
          <Container>
            <h1>Courses</h1>
          </Container>
        </div>
        <Container>
          <Grid container spacing={4} style={{ marginBottom: "60px" }}>
            <ComboCourseList />
            <BlogRoll />
          </Grid>
        </Container>
      </Layout>
    )
  }
}
