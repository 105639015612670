import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "gatsby"
import React from "react"

import CibtacLogo from "./cibtacLogo"
import ItecLogo from "./itecLogo"

export interface BlogCardProps {
  title: string
  description: string
  level: string
  itec: boolean
  cibtac: boolean
  link: string
  rqf: boolean
  nqfLevel: string
  vtctLevel: string
  newCourse: boolean
}

const truncateString = (string: string, limit: number) => {
  if (string.length <= limit) {
    return string
  }
  return string.slice(0, limit) + "..."
}

const BlogCard = (props: BlogCardProps) => {
  const {
    title,
    description,
    level,
    itec,
    cibtac,
    link,
    rqf,
    nqfLevel,
    vtctLevel,
    newCourse,
  } = props
  return (
    <Link
      to={`${link}`}
      aria-label={`${title} info`}
      style={{ textDecoration: "none" }}
    >
      <Card raised>
        <CardContent>
          {itec || cibtac ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
                height: "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                {itec && <ItecLogo />}
                {cibtac && <CibtacLogo />}
              </div>
              {newCourse && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#7f8fa6",
                    height: "28px",
                    borderRadius: "4px",
                  }}
                >
                  <p
                    style={{
                      padding: "2px 4px",
                      color: "white",
                      margin: 0,
                    }}
                  >
                    NEW
                  </p>
                </div>
              )}
            </div>
          ) : null}
          <article>
            <header>
              <h3>{title}</h3>
              <h4>
                Level {level} {rqf ? " (RQF) " : ""}
                {nqfLevel ? ` NQF IE Level ${nqfLevel}` : ""}
                {vtctLevel ? ` VTCT Level ${vtctLevel}` : ""}
              </h4>
            </header>
            <p>{truncateString(description, 300)}</p>
          </article>
        </CardContent>
        <CardActions>
          <Button size="medium">Learn More</Button>
        </CardActions>
      </Card>
    </Link>
  )
}
export default BlogCard
