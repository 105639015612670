import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

interface Props {
  maxWidth?: number
}

const CibtacLogo = ({ maxWidth = 50 }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      cibtacLogo: file(relativePath: { eq: "cibtac_logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
        maxWidth: `${maxWidth}px`,
        display: "flex",
      }}
    >
      <Img
        style={{ flex: 1 }}
        fluid={data.cibtacLogo.childImageSharp.fluid}
        alt="The Institute of Beauty Logo"
      />
    </div>
  )
}

export default CibtacLogo
