import Grid from "@material-ui/core/Grid"
import { graphql, StaticQuery } from "gatsby"
import React from "react"

import BlogCard from "./blogCard"

interface Props {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            excerpt: string
            id: string
            fields: {
              slug: string
            }
            frontmatter: {
              [key: string]: any
            }
          }
        }
      ]
    }
  }
}

class BlogRoll extends React.Component<Props> {
  public render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <Grid item xs={12} sm={4} key={post.id}>
              <BlogCard
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                level={post.frontmatter.level}
                itec={post.frontmatter.itec}
                cibtac={post.frontmatter.cibtac}
                link={post.fields.slug}
                newCourse={post.frontmatter.newCourse}
                rqf={post.frontmatter.rqf}
                nqfLevel={post.frontmatter.nqfLevel}
                vtctLevel={post.frontmatter.vtctLevel}
              />
            </Grid>
          ))}
      </>
    )
  }
}

const BlogRollContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: { frontmatter: { templateKey: { eq: "course" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  itec
                  cibtac
                  description
                  level
                  rqf
                  nqfLevel
                  newCourse
                  vtctLevel
                }
              }
            }
          }
        }
      `}
      render={(data) => <BlogRoll data={data} />}
    />
  )
}

export default BlogRollContainer
