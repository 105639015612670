import Grid from "@material-ui/core/Grid"
import { graphql, StaticQuery } from "gatsby"
import React from "react"

import ComboCard from "./comboCard"

interface Props {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            excerpt: string
            id: string
            fields: {
              slug: string
            }
            frontmatter: {
              [key: string]: any
            }
          }
        }
      ]
    }
  }
}

class ComboCourseList extends React.Component<Props> {
  public render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <Grid item xs={12} sm={4} key={post.id}>
              <ComboCard
                title={post.frontmatter.title}
                description={post.excerpt}
                coursesTitles={post.frontmatter.coursesTitles}
                itec={post.frontmatter.itec}
                link={post.fields.slug}
                newCourse={post.frontmatter.newCourse}
              />
            </Grid>
          ))}
      </>
    )
  }
}

const ComboCourseListContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query ComboCourseQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: { frontmatter: { templateKey: { eq: "combo-course" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 300)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  itec
                  description
                  coursesTitles
                  newCourse
                }
              }
            }
          }
        }
      `}
      render={(data) => <ComboCourseList data={data} />}
    />
  )
}

export default ComboCourseListContainer
