import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

interface Props {
  maxWidth?: number
}

const ItecLogo = ({ maxWidth = 120 }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      itecLogo: file(relativePath: { eq: "itec_logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
        maxWidth: `${maxWidth}px`,
        display: "flex",
        marginRight: "16px",
      }}
    >
      <Img
        style={{ flex: 1 }}
        fluid={data.itecLogo.childImageSharp.fluid}
        alt="The Institute of Beauty Logo"
      />
    </div>
  )
}

export default ItecLogo
