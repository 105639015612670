import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "gatsby"
import React from "react"

import ItecLogo from "./itecLogo"

export interface ComboCardProps {
  title: string
  description: string
  coursesTitles: Array<string>
  itec: boolean
  link: string
  newCourse: boolean
}

const ComboCard = (props: ComboCardProps) => {
  const { title, description, coursesTitles, itec, link, newCourse } = props
  return (
    <Link
      to={`${link}`}
      aria-label={`${title} info`}
      style={{ textDecoration: "none" }}
    >
      <Card raised>
        <CardContent>
          {itec ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
                height: "50px",
              }}
            >
              {itec && <ItecLogo />}
              {newCourse && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#7f8fa6",
                    borderRadius: "4px",
                  }}
                >
                  <p style={{ padding: "2px 4px", color: "white", margin: 0 }}>
                    NEW
                  </p>
                </div>
              )}
            </div>
          ) : null}
          <article>
            <header>
              {coursesTitles.map((t, index) => (
                <h4 style={{ fontSize: "1.25rem" }} key={`title_${index}`}>
                  {t}
                </h4>
              ))}
            </header>
            <p>{description}</p>
          </article>
        </CardContent>
        <CardActions>
          <Button size="medium">Learn More</Button>
        </CardActions>
      </Card>
    </Link>
  )
}
export default ComboCard
